<template>
  <popup
      title="Гайд по домонетизации"
      html-id="guidePopup"
      @close="$emit('close')"
  >
    <p><strong>Домонетизация</strong> - это встраиваемый скрипт, который при определенном действии пользователя превращает транзитку, ленд или транзитколенд в витрину тизеров. Такая механика позволяет “дожать” аудиторию, даже если он не целевой, и сделать конверсию в целевой лид. </p>
    <p>На данной странице мы предлагаем вам скрипт, который вы можете скопировать на любой ваш ленд и использовать как отдельную опцию.</p>
    <p><strong>Шаг 1:</strong> Выберете из списка доменов тот, который вы хотите использовать в качестве витрины тизеров, будьте внимательны, так как разные домены используются под разные гео, а, соответственно, и витрина тизеров будет на разных языках.
      <br> Ориентируйтесь на фильтр по гео.</p>
    <p><strong>Шаг 2:</strong> Активируйте кнопку с выбором варианта домонетизации.</p>
    <p><strong>Шаг 3:</strong> Скопируйте появившийся скрипт.</p>
    <p><strong>Шаг 4:</strong> Вставите его в tag script на любой ваш ленд. Скрипт не блокирует загрузку ленда, поэтому вставить скрипт можно как в &lt;head&gt;, так и в конец &lt;body&gt;. <span>Со своей стороны мы советуем вариант с &lt;body&gt;</span>.</p>
    <p>Ваша статистика по домонетизации будет отражена в соответствующем разделе нашей “лаборатории”!</p>
  </popup>
</template>

<script>

import { defineComponent } from "vue";
import Popup from "../../components/Popup";

export default defineComponent({
  components: {
    Popup
  },
  emits: ['close']
})
</script>

<style lang="scss" scoped>
* {
  max-width: 600px;
}

p + p {
  margin-top: 20px;
}

p {
  line-height: 24px;

  span {
    text-decoration: underline;
  }
}
</style>